(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:LicenseTypes
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('LicenseTypes', LicenseTypes);

  function LicenseTypes($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/licensetypes');
  }
}());
